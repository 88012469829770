import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import {
  burnabyGeoJson,
  richmondGeoJson,
  vancouverGeoJson,
} from '../data/locationsData'
import { primaryColor } from '../styles/styleVariables'

const MapBox = () => {
  const mapContainer = useRef(null)
  const map = useRef(null)

  mapboxgl.accessToken = process.env.GATSBY_MAPBOX_KEY

  useEffect(() => {
    if (map.current) return // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/light-v10',
      center: [-123.0507, 49.2127],
      zoom: 10.5,
      scrollZoom: false,
      doubleClickZoom: false,
    })

    map.current.on('load', () => {
      map.current.addSource('burnaby', {
        type: 'geojson',
        data: burnabyGeoJson,
      })
      map.current.addLayer({
        id: 'burnaby-fill',
        type: 'fill',
        source: 'burnaby',
        paint: {
          'fill-color': `${primaryColor}`,
          'fill-opacity': 0.5,
        },
      })
      map.current.addLayer({
        id: 'burnaby-line',
        type: 'line',
        source: 'burnaby',
        paint: {
          'line-color': `${primaryColor}`,
          'line-width': 3,
        },
      })

      map.current.addSource('richmond', {
        type: 'geojson',
        data: richmondGeoJson,
      })
      map.current.addLayer({
        id: 'richmond-fill',
        type: 'fill',
        source: 'richmond',
        paint: {
          'fill-color': `${primaryColor}`,
          'fill-opacity': 0.5,
        },
      })
      map.current.addLayer({
        id: 'richmond-line',
        type: 'line',
        source: 'richmond',
        paint: {
          'line-color': `${primaryColor}`,
          'line-width': 3,
        },
      })

      map.current.addSource('vancouver', {
        type: 'geojson',
        data: vancouverGeoJson,
      })
      map.current.addLayer({
        id: 'vancouver-fill',
        type: 'fill',
        source: 'vancouver',
        paint: {
          'fill-color': `${primaryColor}`,
          'fill-opacity': 0.5,
        },
      })
      map.current.addLayer({
        id: 'vancouver-line',
        type: 'line',
        source: 'vancouver',
        paint: {
          'line-color': `${primaryColor}`,
          'line-width': 3,
        },
      })
    })
  }, [])

  return <MapBoxComponent ref={mapContainer} />
}

export default MapBox

const MapBoxComponent = styled.div`
  margin-top: 2rem;
  height: 700px;
  width: 100%;
`
