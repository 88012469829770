import React from 'react'
import ContactForm from '../components/ContactForm'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { PageTitle, SubTitle } from '../components/Titles'
import 'mapbox-gl/dist/mapbox-gl.css'
import MapBox from '../components/MapBox'

const locations = () => {
  return (
    <Layout>
      <Seo title='Locations' />
      <PageTitle>Locations</PageTitle>
      <SubTitle>
        We proudly service these current areas in the Greater Vancouver Area
      </SubTitle>
      <MapBox />
      <ContactForm />
    </Layout>
  )
}

export default locations
